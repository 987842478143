import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { SimpleLoader } from "./simpleLoader";
import { getOrganization } from "../../utils";
import api, { apiService } from "../../common/api";
import SelectableEvent from "../event/selectableEvent";
import db from "../../common/db";
import { scrollNearTo, sortArray } from "../../common/helpers";
import { ToastNotificationSuccess } from ".";
import { FormattedMessage } from "react-intl";
import { getGrounds } from "../../page/event/redux/getGrounds";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getEventGroups } from "../../utils/getEventGroups";
import moment from "moment";

const ExportToEvent = (props) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
    Events: [],
    SelectedEvents: [],
  });

  let containerScroller = useRef();

  useEffect(() => {
    //Request events locally

    loadEvents();

    setTimeout(() => {
      let se = document.getElementsByClassName("search")[0];
      if (se) {
        se.focus();
      }
    }, 1000);
  }, []);

  const loadEvents = async () => {
    let organization = getOrganization();
    let event = await db
      .table("events")

      .where({ OrganizationGUID: organization.GUID })
      .toArray();

    if (event.length > 0) {
      event = event.filter((x) => x.Deleted === null && x.Date !== null);
      //See if we need to apply some filters here
      if (search.length > 0) {
        let simplifiedSearch = search.toLowerCase().trim();
        event = event.filter(
          (x) =>
            x.Name.toLowerCase().includes(simplifiedSearch) ||
            x.City.toLowerCase().includes(simplifiedSearch) ||
            x.EventType.toLowerCase().includes(simplifiedSearch)
        );
      }
      event = sortArray(event, "Date");
      setState({ ...state, Events: event });
    }
    //Search event if contact
    if (props.section === "contact") {
      scrollNearTo(containerScroller.current, event, props.originEvent);
    } else {
      if (props.originEvent) {
        scrollNearTo(containerScroller.current, event, props.originEvent.GUID);
      }
    }
  };
  const toggleEvent = (eventSelected) => {
    let copy = state.SelectedEvents;

    //For export we can only do one by one
    if (props.exportMode === "export") {
      copy = [];
    }
    let exist = copy.filter((x) => x.GUID === eventSelected.GUID)[0];
    if (exist) {
      //means we must remove it
      copy = copy.filter((x) => x.GUID !== eventSelected.GUID);
    } else {
      //we must add to the list
      copy.push(eventSelected);
    }
    setState({
      ...state,
      SelectedEvents: copy,
    });
  };
  const handleSubmit = async () => {
    let query = {
      Items: "",
      Events: "",
    };

    props.items.forEach((item) => {
      query.Items += (query.Items.length > 0 ? ";" : "") + item.GUID;
    });
    state.SelectedEvents.forEach((event) => {
      query.Events += (query.Events.length > 0 ? ";" : "") + event.GUID;
    });

    switch (props.section) {
      case "attachment":
        if (props.exportMode === "export") {
          await apiService.post("/attachments/exportToAnotherEvent", query);
          //do the update locally
          let records = query.Items.split(";");
          records.forEach(async (record) => {
            let reecordLocal = await db
              .table("attachments")
              .where({ GUID: record })
              .first();
            reecordLocal.EventGUID = query.Events.split(";")[0];
            await db.table("attachments").put(reecordLocal);
          });
        } else if (props.exportMode === "copy") {
          await apiService.post("/attachment/copyToAnotherEvent", query);
        }
        break;
      case "contact":
        if (props.exportMode === "export") {
          await api.post("/ProductionVenueContact/exportToAnotherEvent", query);
          //do the update locally
          let records = query.Items.split(";");
          records.forEach(async (record) => {
            let reecordLocal = await db
              .table("contacts")
              .where({ GUID: record })
              .first();
            reecordLocal.EventGUID = query.Events.split(";")[0];
            await db.table("contacts").put(reecordLocal);
          });
        } else if (props.exportMode === "copy") {
          await api.post("/ProductionVenueContact/copyToAnotherEvent", query);
        }
        break;
      case "ground":
        if (props.exportMode === "export") {
          await api.post("/grounds/exportToAnotherEvent", query);
          //do the update locally
          let records = query.Items.split(";");
          records.forEach(async (record) => {
            let reecordLocal = await db
              .table("grounds")
              .where({ GUID: record })
              .first();
            reecordLocal.EventGUID = query.Events.split(";")[0];
            await db.table("grounds").put(reecordLocal);
          });
        } else if (props.exportMode === "copy") {
          await api.post("/grounds/copyToAnotherEvent", query);
        }
        break;
      case "hotel":
        if (props.exportMode === "export") {
          await api.post("/hotels/exportToAnotherEvent", query);
          //do the update locally
          let records = query.Items.split(";");
          records.forEach(async (record) => {
            let reecordLocal = await db
              .table("hotels")
              .where({ GUID: record })
              .first();
            reecordLocal.EventGUID = query.Events.split(";")[0];
            await db.table("hotels").put(reecordLocal);
          });
        } else if (props.exportMode === "copy") {
          await api.post("/hotels/copyToAnotherEvent", query);
        }
        break;

      case "itinerary":
        if (props.exportMode === "export") {
          await api.post("/Schedules/exportToAnotherEvent", query);
          //do the update locally
          let records = query.Items.split(";");
          records.forEach(async (record) => {
            let reecordLocal = await db
              .table("schedules")
              .where({ GUID: record })
              .first();
            reecordLocal.EventGUID = query.Events.split(";")[0];
            await db.table("schedules").put(reecordLocal);
          });
        } else if (props.exportMode === "copy") {
          await apiService.post("/ScheduleMaster/copyToAnotherEvent", query);
        }
        break;
      case "flight":
        if (props.exportMode === "export") {
          await api.post("/flights/exportToAnotherEvent", query);
          //do the update locally
          let records = query.Items.split(";");
          records.forEach(async (record) => {
            let reecordLocal = await db
              .table("flights")
              .where({ GUID: record })
              .first();
            if (reecordLocal) {
              reecordLocal.EventGUID = query.Events.split(";")[0];
              await db.table("flights").put(reecordLocal);
            }
          });
        }
        break;
      default:
        break;
    }
    ToastNotificationSuccess(1, "Items Succesfully Updated");
    props.toggle(props.exportMode, true);
    dispatch(getGrounds(props.originEvent.GUID));
  };
  const filter = (e) => {
    setSearch(e);
    loadEvents();
  };

  function addOneYearToDate(inputDate) {
    if (!(inputDate instanceof Date)) {
      return "Invalid Date";
    }
    const newDate = new Date(inputDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    return newDate;
  }

  const [isFilter, setIsFilter] = useState(false);
  const [eventFilterState, setEventFilterState] = useState({
    from: new Date(),
    to: addOneYearToDate(new Date()),
    group: null,
  });

  function filterEvents(localEvents, filterState) {
    const { from, to, group } = filterState;

    const minDate = new Date(); // Minimum possible date (January 1, 1970)
    const maxDate = new Date(8640000000000000); // Maximum possible date (December 31, 275760)

    // Use the default values for from and to if not provided
    const fromDate = from === "" ? minDate : new Date(from);
    const toDate = to === "" ? maxDate : new Date(to);

    const filteredEvents = localEvents.filter((event) => {
      if (from === "" && to === "" && !group) {
        return true;
      }

      const eventDate = new Date(event.Date);
      // Check if the event's date is within the specified range

      const isDateInRange = eventDate >= fromDate && eventDate <= toDate;

      // Filter by groups if groupsData is not an empty array
      const isGroupIncluded = !group
        ? true
        : group?.GUID === event?.EventGroupGUID;

      // Combine conditions (e.g., both group and status conditions must match)

      return isDateInRange && isGroupIncluded;
    });

    return filteredEvents;
  }

  const eventGroups = state.Events ? getEventGroups(state.Events) : [];

  const handleFilterFrom = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        from: data,
      };
    });
  };

  const handleFilterTo = (data) => {
    setEventFilterState((prev) => {
      return {
        ...prev,
        to: data,
      };
    });
  };

  const handleFilterByGroup = (e, item) => {
    const GUID = e.target.value;
    const [group] = eventGroups.filter((group) => group.GUID === GUID);
    setEventFilterState((prev) => {
      return {
        ...prev,
        group,
      };
    });
  };

  const filterCleanedEvents = filterEvents(state.Events, eventFilterState);

  const eventToRender = true ? filterCleanedEvents : state.Events;

  const handleDateChange = (e, direction) => {
    if (direction === "from") {
      handleFilterFrom(e.target.value);
    } else if (direction === "to") {
      handleFilterTo(e.target.value);
    }
  };

  return (
    <>
      <div className="organization-transparent">
        <div className="event-page">
          <div className="event-expennd-section move-modal">
            <div className="text-head headerBg">
              <div className="text-left">
                <h1>
                  {props.exportMode === "export" ? (
                    <FormattedMessage
                      id="exportToAnotherEvent"
                      defaultMessage="Export to another event"
                    />
                  ) : (
                    <FormattedMessage
                      id="copyToAnotherEvent"
                      defaultMessage="Copy to another event(s)"
                    />
                  )}
                </h1>
              </div>
              {/* <div className="text-right formAlternativeStyle">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="search"
                  value={search}
                  onChange={(e) => filter(e.target.value)}
                ></input>
              </div> */}

              <div className="text-black-50 d-flex align-items-center justify-content-between">
                <label className="mx-2" htmlFor="dateFrom">
                  Filters:
                </label>
                <input
                  className="form-control py-2 px-2 bg-light rounded-lg"
                  name="dateFrom"
                  type="date"
                  value={moment(eventFilterState.from).format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChange(e, "from")}
                />
                <label className="mx-2" htmlFor="dateTo">
                  -
                </label>
                <input
                  name="dateTo"
                  type="date"
                  className="form-control py-2 px-2 bg-light rounded-lg"
                  value={moment(eventFilterState.to).format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChange(e, "to")}
                />

                <select
                  className="form-control mx-2 p-2 align-self-end bg-light rounded-lg"
                  defaultValue={
                    eventFilterState?.group?.Name === null
                      ? "Select"
                      : eventFilterState?.group?.Name
                  }
                  onChange={handleFilterByGroup}
                >
                  <option>--Select Group--</option>
                  {eventGroups.map((group) => (
                    <option
                      key={group.GUID}
                      value={group.GUID}
                      selected={eventFilterState?.group?.GUID === group.GUID}
                    >
                      {group.Name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="event-inside move px-1">
              <div className="move-section">
                <Scrollbars
                  ref={containerScroller}
                  style={{ width: "100%", height: `calc(100vh - 120px)` }}
                >
                  {eventToRender?.length > 0 ? (
                    eventToRender.map((event) => {
                      return (
                        <div className="card my-1 shadow-sm " key={event.GUID}>
                          <div className="card-body p-0">
                            <SelectableEvent
                              event={event}
                              toggleEvent={toggleEvent}
                              eventsSelected={state.SelectedEvents}
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="alert alert-info text-center mt-1 w-100">
                      No Event Found
                    </div>
                  )}
                </Scrollbars>
              </div>
            </div>

            <div className="event-content">
              {state.UI.isLoading ? (
                <SimpleLoader loading={state.UI.isLoading} />
              ) : (
                <div className="two-buttons">
                  <button
                    onClick={() => handleSubmit()}
                    type="button"
                    className="btn primary"
                  >
                    <FormattedMessage id="done" defaultMessage="Done" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportToEvent;
